import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const formatPrice = (amountInCents) => {
  return (amountInCents / 100).toLocaleString("en-US", { 
    style: "currency", 
    currency: "USD" 
  })
}

const formatSlug = (name) => {
  return name.split(" ").join("-").toLowerCase()
  // return <Link>{name}</Link>
}

const FloorPlan = ({ apartment, showFullName, location }) => {
  const splitName = apartment.name.split(" - ")
  const name = splitName[0]
  const propertyName = splitName[splitName.length - 1]
  const { deposit, mainprice, highprice, sqft } = apartment

  console.log('floorplan data', apartment)

  return (
    <div className="floorplan">
      
      <div className="header">
        {/* REVIEW do we need the name twice here? may be redundant */}
        {showFullName ? 
          <div className="name full-name">
            <span>{name}</span>
            <span className="property-name">
              <Link to={`/properties/${formatSlug(propertyName)}`}>
                {propertyName}
              </Link>
            </span>
          </div>
          : 
          <div className="name">
            {name} 
          </div>
        }
        <div className="details">
          <ul>
            <li>{apartment.bathrooms} Bath</li>
            <li>{sqft} sqft</li>
          </ul>
        </div>
      </div>
      <div className="main-content">
        <div className="floorplan-image">
          {/* <img src="https://via.placeholder.com/300" alt="" /> */}
          <Link to={`/properties/${formatSlug(propertyName)}`}>
            <Img fluid={apartment.floorplan.asset?.fluid} />
          </Link>
        </div>
        <div className="rent-price">
          { highprice ? 
            <p>
              {`${formatPrice(mainprice)} - ${formatPrice(highprice)} `}
              <span>Per Month</span>
            </p>
            :
            <p>
              {`${formatPrice(mainprice)} `}
              <span>Per Month</span>
            </p>
          }
        </div>
        <div className="deposit-price">
          <p>
            {formatPrice(deposit)}
            <span>Deposit</span>
          </p>
        </div>
      </div>
      <div className="floorplan-footer">
        <a 
          href="https://taylorhillproperties.appfolio.com/listings" 
          target="_blank" 
          className="btn">
            Apply Now
        </a>
      </div>
    </div>
  )
}

const ApartmentList = ({ apartments, showFullName, location }) => {
  return (
    <div className="apartment-list">
      {apartments.map((apartment) => (
        <FloorPlan 
          key={apartment.id} 
          apartment={apartment} 
          showFullName={showFullName} 
          location={location}
        />
      ))}
    </div>
  )
}

export default ApartmentList;