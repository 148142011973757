import { graphql } from 'gatsby';
import React, { useState } from 'react'
import ApartmentList from '../../components/ApartmentList';
import ImageGallery from '../../components/ImageGallery';
// import Layout from '../components/layout'
import Seo from '../../components/seo'

// TODO add bathroom count to schema and all apartments

const Location = ({data}) => {
  const location = data.location
  const address = data.location.address
  const streetAddress = address.split(", ")[0]
  const cityState = address.split(", ")[1]
  const zip = address.split(", ")[2]

  const images = location.apartments.map(apartment => apartment.images).flat()

  const [galleryToggle, setGalleryToggle] = useState('gallery')

  const handleSelected = (className) => className === galleryToggle ? 'selected' : null

  // debugger;
  
  return (
    <div className='location-apartment-container'>
      <Seo title={`${location.name} Apartments, Abilene TX`} />
      {/* TODO add and display images */}
      <div className="location-details">
        <p>{location.name} Apartments</p>
        <p>{streetAddress}</p>
        <p>{cityState}, {zip}</p>
      </div>

      <div className="gallery-toggle">
        <span 
          className={`gallery-toggle-link ${handleSelected('gallery')}`}
          onClick={() => setGalleryToggle('gallery')}>Gallery
        </span> | 
        <span 
          className={`gallery-toggle-link ${handleSelected('floorplans')}`}
          onClick={() => setGalleryToggle('floorplans')}> Floorplans
        </span>
      </div>

      { galleryToggle === 'gallery' ? 
        <ImageGallery images={images} />
        :
        <ApartmentList apartments={location.apartments} location={location} />
      }

    </div>
  )
}

export default Location

export const query = graphql`
  query LocationQuery($id: String) {
    location: sanityLocation(id: {eq: $id}) {
      id
      name
      address
      apartments {
        id
        name
        mainprice
        highprice
        sqft
        deposit
        bathrooms
        floorplan {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
            fixed(width: 300) {
              ...GatsbySanityImageFixed
            }
          }
        }
        images {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;