import React, { useState } from 'react'
// import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { HiOutlineArrowNarrowRight, HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'
import { useEffect } from 'react'

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0])
  const [galleryDisplayIndex, setGalleryDisplayIndex] = useState(0)

  /* TODO create 2 sections 
            - one for current selected / default image
            - and one for a list of thumbnails
  */

  /* TODO create state to track selected image
          - when thumbnail is clicked, set that image as selected
          - show the selected image in the main div
          - remove the selected image from thumbnails? nah
  */

  useEffect(() => {
    setSelectedImage(images[galleryDisplayIndex])
  }, [galleryDisplayIndex, images])

  const displayCards = images.map((image, index) => {
    return (
      <div 
        className="gallery-card"
        onClick={() => setSelectedImage(image)}
        key={`image-${index}`}
      >
        <Img 
          fluid={image.asset?.fluid} 
          className="gallery-image"
        />
      </div>
    )
  })

  const handleGalleryShift = (direction) => {
    if (direction === 'next') {
      setGalleryDisplayIndex(prevState => prevState + 1)
    } else {
      setGalleryDisplayIndex(prevState => prevState - 1)
    }
  }

  return (
    <div className="image-gallery">
      <div className="selected-image">
        <Img 
          fluid={selectedImage.asset.fluid} 
          className=""
          style={{ height: "100%", width: "100%" }}
          imgStyle={{ objectFit: "contain" }}
        />

        <div className="gallery-nav">
          {/* Only show an arrow if there's room to move in that direction */}
          {
            galleryDisplayIndex > 0 ? 
            <FiArrowLeftCircle className="gallery-arrow" onClick={() => handleGalleryShift('prev')}/>
            : <div className="empty-placeholder"></div>
          }
          {
            (galleryDisplayIndex + 1) < images.length ? 
            <FiArrowRightCircle className="gallery-arrow" onClick={() => handleGalleryShift('next')}/>
            : null
          }
        </div>
      </div>
      
      <div className="thumbnails">
        {displayCards}
      </div>
    </div>
  )
}

export default ImageGallery;